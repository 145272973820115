import { List, ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CategoryIcon } from 'components';
import { Categories } from 'system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      marginTop: theme.spacing(1),
    },
  })
);

type Props = {
  onItemSelect: (selected: string) => void;
};

export default function CategoryList({ onItemSelect }: Props) {
  const styles = useStyles();
  const categories = Object.keys(Categories);

  return (
    <List className={styles.root}>
      {categories.map((category, ix) => (
        <ListItem button key={`${ix}--${category}`} onClick={() => onItemSelect(category)}>
          <ListItemIcon>
            <CategoryIcon category={category} />
          </ListItemIcon>
          <ListItemText primary={category} />
        </ListItem>
      ))}
    </List>
  );
}
