import { ApolloClient } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { Dispatch } from 'react';
import { AuthAction, CognitoUser } from './types';

export enum AuthActions {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  SET_VISIT = 'SET_VISIT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export const loginUser = async (
  dispatch: Dispatch<AuthAction>,
  { challenge, email }: { challenge: string; email: string }
) => {
  let user: CognitoUser | undefined = undefined;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const challengedUser = await Auth.signIn(email);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    user = await Auth.sendCustomChallengeAnswer(challengedUser, challenge);
  } catch (e) {
    return {
      error: (e as Error).message,
    };
  }

  if (user?.attributes && user?.signInUserSession) {
    const { 'custom:id': id, 'custom:account': accountId } = user.attributes;
    const {
      idToken: { jwtToken: userToken },
      refreshToken: { token: userRefreshToken },
    } = user.signInUserSession;

    const authPayload = {
      userToken,
      userRefreshToken,
      attributes: { id, accountId },
    };

    localStorage.setItem('currentUserCredentials', JSON.stringify(authPayload));
    dispatch({ type: AuthActions.SIGN_IN, payload: authPayload });
  } else {
    return {
      error: 'Challenge not accepted for authentication',
    };
  }

  return {
    success: true,
  };
};

export const logoutUser = async (
  dispatch: Dispatch<AuthAction>,
  client?: ApolloClient<unknown>
) => {
  dispatch({ type: AuthActions.SIGN_OUT });
  if (client) await client.cache.reset();
  try {
    await Auth.signOut();
  } catch (e) {
    console.error(e);
  }
};

export const handleErrorRefresh = async (dispatch: Dispatch<AuthAction>) => {
  const session = await Auth.currentSession();
  const userToken = session.getIdToken().getJwtToken();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const storedAuthPayload = JSON.parse(localStorage.getItem('currentUserCredentials') ?? '{}');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const payload = {
    ...storedAuthPayload,
    userToken,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  dispatch({ type: AuthActions.SIGN_IN, payload });
};
