import { Image, useCompleteVisitMutation, useDeclineVisitMutation, useGetVisitQuery } from 'api';
import { useAllErrors, useNotification } from 'hooks';
import { useEffect, useState } from 'react';
import { Domain, ensureArray } from 'system';
import { useLoading } from './useLoading';

enum Notifications {
  complete = 'Visit completed successfully.',
  decline = 'Visit declined successfully.',
}

const imageKeyMatchesDomain =
  (domain: Domain) =>
  ({ key }: Image) =>
    new RegExp(`(${domain})/([^/]+)([/]?)$`).test(key ?? '');

export const useVisit = ({ visitId }: { visitId: string }) => {
  const [tenantImages, setTenantImages] = useState<Image[]>([]);
  const [operatorImages, setOperatorImages] = useState<Image[]>([]);
  const [managerImages, setManagerImages] = useState<Image[]>([]);
  const { sendSuccessNotification } = useNotification();
  const {
    data,
    loading: loadingQuery,
    error: errorQuery,
  } = useGetVisitQuery({
    variables: { id: visitId },
  });
  const [completeVisitMutation, { loading: loadingComplete, error: errorComplete }] =
    useCompleteVisitMutation();
  const [declineVisitMutation, { loading: loadingDecline, error: errorDecline }] =
    useDeclineVisitMutation();

  const loading = useLoading(loadingQuery, loadingComplete, loadingDecline);
  useAllErrors(errorComplete, errorDecline, errorQuery);

  useEffect(() => {
    if (data?.visit) {
      setTenantImages([
        ...ensureArray(data.visit.request?.images).filter(imageKeyMatchesDomain('tenant')),
        ...ensureArray(data.visit.images?.filter(imageKeyMatchesDomain('tenant'))),
      ]);
      setManagerImages([
        ...ensureArray(data.visit.request?.images).filter(imageKeyMatchesDomain('manager')),
        ...ensureArray(data.visit.images?.filter(imageKeyMatchesDomain('manager'))),
      ]);
      setOperatorImages(ensureArray(data.visit.images?.filter(imageKeyMatchesDomain('operator'))));
    }
  }, [data]);

  const removeImageKeyFromState = (key: string) =>
    setOperatorImages(operatorImages.filter((image) => image.key !== key));

  const completeVisit = () =>
    completeVisitMutation({
      variables: {
        input: {
          visitId,
        },
      },
    }).then(() => sendSuccessNotification(Notifications.complete));

  const declineVisit = () =>
    declineVisitMutation({
      variables: {
        input: {
          visitId,
        },
      },
    });

  return {
    visit: data?.visit,
    tenantImages,
    operatorImages,
    managerImages,
    removeImageKeyFromState,
    completeVisit,
    declineVisit,
    loading,
  };
};
