export default function UnableToComplete() {
  return (
    <svg width="94" height="91" viewBox="0 0 94 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.75 25.625H57.375V7.875H22.375V26H4.25V76.125H10.875V32.625H29V14.5H50.75V25.625Z"
        fill="#111111"
      />
      <path d="M25.375 39.875H18.125V47.125H25.375V39.875Z" fill="#111111" />
      <path d="M43.375 53.875H36.125V61.125H43.375V53.875Z" fill="#111111" />
      <path d="M43.375 39.875H36.125V47.125H43.375V39.875Z" fill="#24D1AD" />
      <path d="M43.5 18.125H36.25V25.375H43.5V18.125Z" fill="#111111" />
      <path d="M25.375 54.375H18.125V61.625H25.375V54.375Z" fill="#24D1AD" />
      <path d="M25.375 68.875H18.125V76.125H25.375V68.875Z" fill="#111111" />
      <path
        d="M43.5 75.25C43.5 74.2389 43.9906 73.2053 45.1521 72.1333C46.3272 71.0488 48.0506 70.0653 50.1155 69.2381C54.2485 67.5824 59.2456 66.75 62.5 66.75C65.7544 66.75 70.7515 67.5824 74.8845 69.2381C76.9494 70.0653 78.6728 71.0488 79.8479 72.1333C81.0094 73.2053 81.5 74.2389 81.5 75.25V78.5H43.5V75.25Z"
        stroke="#561BD6"
        strokeWidth="4"
      />
      <path
        d="M76.3757 48.8392C76.3757 40.3754 69.8082 34.625 62.5007 34.625C55.2702 34.625 48.6257 40.2521 48.6257 48.9317C47.7007 49.4558 47.084 50.4425 47.084 51.5833V54.6667C47.084 56.3625 48.4715 57.75 50.1673 57.75H51.709V48.3458C51.709 42.3796 56.5344 37.5542 62.5007 37.5542C68.4669 37.5542 73.2923 42.3796 73.2923 48.3458V59.2917H60.959V62.375H73.2923C74.9882 62.375 76.3757 60.9875 76.3757 59.2917V57.4108C77.2852 56.9329 77.9173 55.9925 77.9173 54.8825V51.3367C77.9173 50.2575 77.2852 49.3171 76.3757 48.8392Z"
        fill="#561BD6"
      />
      <path
        d="M57.8757 51.5833C58.7271 51.5833 59.4173 50.8931 59.4173 50.0417C59.4173 49.1902 58.7271 48.5 57.8757 48.5C57.0242 48.5 56.334 49.1902 56.334 50.0417C56.334 50.8931 57.0242 51.5833 57.8757 51.5833Z"
        fill="#561BD6"
      />
      <path
        d="M67.1257 51.5833C67.9771 51.5833 68.6673 50.8931 68.6673 50.0417C68.6673 49.1902 67.9771 48.5 67.1257 48.5C66.2742 48.5 65.584 49.1902 65.584 50.0417C65.584 50.8931 66.2742 51.5833 67.1257 51.5833Z"
        fill="#561BD6"
      />
      <path
        d="M71.7514 47.0046C71.0114 42.6108 67.1881 39.25 62.5785 39.25C57.9073 39.25 52.8814 43.1196 53.2823 49.1937C57.0902 47.6367 59.9577 44.245 60.7748 40.1133C62.7944 44.1679 66.9414 46.9583 71.7514 47.0046Z"
        fill="#561BD6"
      />
    </svg>
  );
}
