import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Header from './Header';
import { Children } from 'system';
import { useReactiveVar } from '@apollo/client';
import { pageState } from 'context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    child: {
      marginTop: '66px', // height of header with text
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        marginTop: '77px',
        maxWidth: '1024px',
      },
    },
  })
);

export default function VisitFrame({ children }: Children) {
  const styles = useStyles();
  const state = useReactiveVar(pageState);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Header title={state.title} />
      <Box className={styles.child}>{children}</Box>
    </Grid>
  );
}
