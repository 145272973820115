import { useEffect, useState } from 'react';

export const useLoading = (...loadingBools: boolean[]) => {
  const [loading, setLoading] = useState<boolean>(loadingBools.some(Boolean));
  useEffect(() => {
    setLoading(loadingBools.some(Boolean));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, loadingBools);
  return loading;
};
