import { VisitFieldsFragment, useAddVisitNoteMutation, useDeleteVisitNoteMutation } from 'api';
import { Domain, CreateNoteFormFields } from 'system';
import { useState, useEffect, useCallback } from 'react';
import { useAllErrors } from 'hooks';

const targetMatchesDomain = (domain: Domain) => (note?: { target?: string }) =>
  note?.target === domain;

export const useVisitNotes = (
  { id: visitId, notes }: Pick<VisitFieldsFragment, 'notes' | 'id'> = {
    id: '',
    notes: [],
  }
) => {
  const [localError, setLocalError] = useState('');
  const [isCreatingNote, setIsCreatingNote] = useState(false);
  const [isDeletingNote, setIsDeletingNote] = useState(false);
  const [tenantNotes, setTenantNotes] = useState<NonNullable<typeof notes>>([]);
  const [managerNotes, setManagerNotes] = useState<NonNullable<typeof notes>>([]);
  const [addVisitNoteMutation, { error: addNoteError }] = useAddVisitNoteMutation();
  const [deleteVisitNoteMutation, { error: deleteNoteError }] = useDeleteVisitNoteMutation();

  useAllErrors(localError, addNoteError, deleteNoteError);

  useEffect(() => {
    setTenantNotes((notes ?? []).filter(targetMatchesDomain('tenant')));
    setManagerNotes((notes ?? []).filter(targetMatchesDomain('manager')));
  }, [notes]);

  const handleCreateNote = useCallback(
    async (note: Required<CreateNoteFormFields>) => {
      setIsCreatingNote(true);

      try {
        await addVisitNoteMutation({
          variables: { input: { ...note, visitId } },
        });
      } catch (e) {
        console.warn(`Couldn't add note to visit`, e);
        setLocalError(`Couldn't add note to visit. Please try again later.`);
      } finally {
        setIsCreatingNote(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visitId]
  );

  const handleDeleteNote = useCallback(
    async (noteId: string) => {
      setIsDeletingNote(true);

      try {
        await deleteVisitNoteMutation({
          variables: { input: { noteId, visitId } },
          update(cache, result) {
            if (!result.data?.deleteVisitNote?.success) {
              return;
            }

            cache.evict({ id: cache.identify({ id: noteId, __typename: 'Note' }) });
            cache.gc();
          },
        });
      } catch (e) {
        console.warn(`Couldn't delete note from visit`, e);
        setLocalError(`Couldn't delete note from visit. Please try again later.`);
      } finally {
        setIsDeletingNote(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visitId]
  );

  return {
    tenantNotes,
    managerNotes,
    isCreatingNote,
    isDeletingNote,
    handleCreateNote,
    handleDeleteNote,
  };
};
