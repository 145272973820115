import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SiteNav from './SiteNav';
import { Children } from 'system';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 73px)', // 73px is the height of the SiteNav
    },
  })
);

export default function DefaultFrame({ children }: Children) {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <SiteNav />
      {children}
    </Grid>
  );
}
