export const colours = {
  shamrock: '#24d1ad',
  waterLeaf: '#bbf3e7',
  elfGreen: '#199279',
  orange: '#FFA800',
  moccasin: '#ffe4b2',
  darkGoldenrod: '#b27500',
  mahogany: '#CF3637',
  black: '#111111',
  whiteSmoke: '#f8f8f8',
  white: '#ffffff',
  purpleHeart: '#561bd6',
  lightSlateBlue: '#9F78FA',
  magnolia: '#ECE4FE',
  cherryPie: '#362A4E',
  nobel: '#9E9E9E',
  nero: '#1D1D1D',
  blackMarlin: '#3D3C41',
  silver: '#C5C5C5',
  whisper: '#EEEEEE',
};
