import { datadogRum } from '@datadog/browser-rum';

const env = process.env.REACT_APP_ENV ?? 'dev';
const version = process.env.REACT_APP_DD_VERSION;

datadogRum.init({
  applicationId: '32f69180-6a6d-4407-88a6-7dc1f289515d',
  clientToken: 'pube109376b98966110f3aa14c16fe20274',
  site: 'datadoghq.com',
  service: `operator-web-${env}`,
  env,
  version,
  sampleRate: 100,
  trackInteractions: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  trackFrustrations: true,
  enableExperimentalFeatures: ['clickmap'],
  defaultPrivacyLevel: 'allow',
});
