import { lazy } from 'react';
import { AppRouteProps } from './AppRoute';

export const routes: AppRouteProps[] = [
  {
    path: '/',
    component: lazy(() => import('../pages/Dashboard')),
    frame: 'DefaultFrame',
    exact: true,
  },
  {
    path: '/magic',
    component: lazy(() => import('../pages/Magic')),
    frame: 'DefaultFrame',
  },
  {
    path: '/visit/:visitId',
    component: lazy(() => import('../pages/Visit')),
    frame: 'VisitFrame',
    isPrivate: true,
  },
  {
    path: '/follow-up/submitted',
    component: lazy(() => import('../pages/FollowUpSubmitted')),
    frame: 'VisitFrame',
  },
  {
    path: '/follow-up/:visitId',
    component: lazy(() => import('../pages/FollowUp')),
    frame: 'VisitFrame',
    isPrivate: true,
  },
  {
    path: '/unable-to-complete/submitted',
    component: lazy(() => import('../pages/UnableToCompleteSubmitted')),
    frame: 'VisitFrame',
    isPrivate: true,
  },
  {
    path: '/unable-to-complete/:visitId',
    component: lazy(() => import('../pages/UnableToComplete')),
    frame: 'VisitFrame',
    isPrivate: true,
  },
  {
    path: '/cancelled',
    component: lazy(() => import('../pages/Cancelled')),
    frame: 'VisitFrame',
    isPrivate: true,
  },
  {
    path: '/*',
    component: lazy(() => import('../pages/NotFound')),
    frame: 'VisitFrame',
  },
];
