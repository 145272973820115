import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { pageState } from './pageState';

export function usePageTitle(title?: string) {
  const state = useReactiveVar(pageState);

  useEffect(() => {
    pageState({ ...state, title: title ?? '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
}
