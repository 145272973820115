import { Chip, ChipProps, Skeleton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { colours } from 'system';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: ({ status }: { status: string }) =>
        ({
          DISABLED: colours.white,
          CANCELLED: colours.white,
          COMPLETED: colours.nero,
          'NEW VISIT REQUESTED': theme.palette.background.default,
        }[status] ?? theme.palette.text.primary),
      fontWeight: 500,
      fontSize: '0.8rem',
      height: '1.3rem',
      cursor: 'pointer',
      backgroundColor: ({ status }: { status: string }) =>
        ({
          COMPLETED: colours.shamrock,
          DISABLED: colours.mahogany,
          CANCELLED: colours.mahogany,
          'NEW VISIT REQUESTED': theme.palette.primary.main,
        }[status] ?? 'transparent'),
      border: ({ status }: { status: string }) =>
        ({
          DEFAULT: `2px solid ${theme.palette.text.secondary}`,
          CONFIRMED: `2px solid ${theme.palette.primary.main}`,
          SCHEDULED: `2px solid ${theme.palette.primary.main}`,
          COMPLETED: `2px solid ${colours.shamrock}`,
          CANCELLED: `2px solid ${colours.mahogany}`,
          STARTED: `2px solid ${colours.shamrock}`,
          MISSED: `2px solid ${theme.palette.warning.main}`,
          DELAYED: `2px solid ${theme.palette.warning.main}`,
          SUBMITTED: `2px solid ${theme.palette.primary.main}`,
          'NEW VISIT REQUESTED': `2px solid ${theme.palette.primary.main}`,
        }[status] ?? `2px solid ${theme.palette.action.disabledBackground}`),
    },
  });
});

export type StatusChipProps = {
  status?: string;
  loading?: boolean;
};

export default function StatusChip({
  status = 'DEFAULT',
  loading = false,
  className,
  ...props
}: StatusChipProps & ChipProps) {
  const styles = useStyles({ status });

  if (loading) return <Skeleton />;

  return <Chip label={status} className={clsx(styles.root, className)} {...props} />;
}
