import { Typography } from '@mui/material';

type HighlightProps = {
  text: string;
  children: string;
};

export default function Highlight({ children, text }: HighlightProps) {
  const beforeText = children.substring(0, children.indexOf(text));
  const afterText = children.substring(children.indexOf(text) + text.length);
  return (
    <>
      {beforeText}
      <Typography color="primary" variant="inherit">
        {' '}
        {text}{' '}
      </Typography>
      {afterText}
    </>
  );
}
