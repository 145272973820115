import { Container as MUIContainer, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
  })
);

export type ContainerProps = {
  className?: string;
  children: NonNullable<ReactNode>;
};

export default function Container({ children, className }: ContainerProps) {
  const styles = useStyles();
  return <MUIContainer className={clsx(styles.root, className)}>{children}</MUIContainer>;
}
