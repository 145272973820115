import { Storage } from '@aws-amplify/storage';
import { useEffect, useState } from 'react';

export const useGetS3Image = (key?: string) => {
  const [image, setImage] = useState<string | undefined>();
  useEffect(() => {
    const fetchImage = async () => {
      if (key) {
        const newImage = await Storage.get(key);
        setImage(newImage.toString());
      }
    };
    void fetchImage();
  }, [key]);
  return { image };
};
