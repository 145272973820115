import { Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';

type Props = {
  inputId: string;
  children?: ReactNode;
  pickImage: (file: File | undefined) => void;
  useButton?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '11px',
      display: 'inline-block',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      textTransform: 'none',
      width: '100%',
    },
    buttonText: {
      color: theme.palette.primary.main,
      fontSize: '13px',
      fontWeight: 500,
      textAlign: 'center',
    },
    input: {
      display: 'none',
    },
  })
);

export default function ImagePicker({ inputId, children, pickImage, useButton = true }: Props) {
  const styles = useStyles();
  return (
    <>
      <input
        accept="image/*"
        className={styles.input}
        id={inputId}
        type="file"
        onChange={(e) => pickImage(e?.target?.files?.[0])}
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
      />
      <label htmlFor={inputId}>
        {useButton && (
          <Button aria-label="upload image" className={styles.button} component="span">
            <Typography className={styles.buttonText}>Add an image</Typography>
          </Button>
        )}
        {children}
      </label>
    </>
  );
}
