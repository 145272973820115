import { ReactNode } from 'react';

export type Children = { children: ReactNode };

export type Occupancy = 'rental' | 'owner' | 'common';

export type Domain = 'tenant' | 'manager' | 'operator';

export type CreateNoteFormFields = { target?: Domain; text?: string };

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export const Provinces = {
  AB: 'AB',
  BC: 'BC',
  MB: 'MB',
  NT: 'NT',
  QC: 'QC',
  SK: 'SK',
  YT: 'YT',
  NU: 'NU',
  ON: 'ON',
  NB: 'NB',
  NL: 'NL',
  NS: 'NS',
  PE: 'PE',
} as const;
