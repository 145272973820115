import { ThemeProvider as MaterialThemeProvider, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { usePrevious } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { Children, colours } from 'system';
import { ThemeContext } from './useTheme';

LicenseInfo.setLicenseKey(
  '985eba06af6ae217db236fe08162589cTz05OTQzOCxFPTE3NTk3NTk5NTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    tertiary: Palette['primary'];
    primaryBackground: Palette['primary'];
    secondaryBackground?: PaletteOptions['secondary'];
  }
  export interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    primaryBackground?: PaletteOptions['primary'];
    secondaryBackground?: PaletteOptions['secondary'];
  }
}

type ThemeMode = 'light' | 'dark';

const useDarkModeDefaults = () => {
  const [theme, setTheme] = useState<ThemeMode>('light');
  const liveSystemDarkMode = false; // useMediaQuery('(prefers-color-scheme: dark)');
  const currentSystemDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches;
  const previousSystemDarkMode = usePrevious(currentSystemDarkMode);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') as ThemeMode | null;
    setTheme(storedTheme !== null ? storedTheme : currentSystemDarkMode ? 'dark' : 'light');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMode = (mode: ThemeMode) => {
    localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const toggleDarkMode = () => setMode(theme === 'light' ? 'dark' : 'light');

  useEffect(() => {
    if (
      currentSystemDarkMode !== undefined &&
      previousSystemDarkMode !== undefined &&
      previousSystemDarkMode !== currentSystemDarkMode
    ) {
      setMode(currentSystemDarkMode ? 'dark' : 'light');
    }
  }, [liveSystemDarkMode, currentSystemDarkMode, previousSystemDarkMode]);

  return { darkMode: theme === 'dark', toggleDarkMode };
};

export default function ThemeProvider({ children }: Children) {
  const { darkMode, toggleDarkMode } = useDarkModeDefaults();
  const themeContext = { toggleDarkMode, darkMode };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          text: {
            secondary: colours.nobel,
          },
          primary: {
            light: colours.lightSlateBlue,
            main: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
          },
          secondary: {
            main: colours.shamrock,
          },
          tertiary: {
            main: darkMode ? colours.whiteSmoke : colours.black,
          },
          success: {
            light: colours.waterLeaf,
            main: colours.shamrock,
            dark: colours.elfGreen,
          },
          warning: {
            light: colours.moccasin,
            main: colours.orange,
            dark: colours.darkGoldenrod,
          },
          error: {
            main: colours.mahogany,
          },
          background: {
            default: darkMode ? colours.black : colours.whiteSmoke,
            paper: darkMode ? colours.nero : colours.white,
          },
          primaryBackground: {
            main: darkMode ? colours.cherryPie : colours.magnolia,
          },
          secondaryBackground: {
            main: colours.waterLeaf,
          },
          divider: darkMode ? colours.blackMarlin : colours.silver,
        },
        components: {
          MuiLink: {
            styleOverrides: {
              root: {
                '&:visited': {
                  color: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
                },
              },
            },
          },
        },
        typography: {
          fontFamily: 'Work Sans',
          ...Object.fromEntries(
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'overline'].map(
              (variant) => [variant, { fontFamily: 'Poppins' }]
            )
          ),
        },
      }),
    [darkMode]
  );

  return (
    <MaterialThemeProvider theme={theme}>
      <ThemeContext.Provider value={themeContext}>{children}</ThemeContext.Provider>
    </MaterialThemeProvider>
  );
}
