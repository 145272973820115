import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Dispatch } from 'react';
import { handleErrorRefresh, logoutUser } from '../actions';
import { AuthAction } from '../types';

let isRefreshing = false;
let pendingRequests: VoidFunction[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: VoidFunction) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = (dispatch: Dispatch<AuthAction>) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.message) {
          case 'Token has expired.':
            if (!isRefreshing) {
              setIsRefreshing(true);

              return fromPromise(
                handleErrorRefresh(dispatch).catch(() => {
                  resolvePendingRequests();
                  setIsRefreshing(false);

                  void logoutUser(dispatch);

                  return forward(operation);
                })
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>((resolve) => {
                  addPendingRequest(() => resolve());
                })
              ).flatMap(() => {
                return forward(operation);
              });
            }
        }
      }
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      void logoutUser(dispatch);
    }
  });

export default errorLink;
