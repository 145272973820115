import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Card, IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MessageModal } from 'components';
import { DateTime } from 'luxon';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
    },
    datetext: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
    noteText: {
      fontSize: '15px',
    },
    noteFooter: {
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
    },
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '5px',
      padding: theme.spacing(1),
      position: 'relative',
      '&:first-child': {
        marginTop: theme.spacing(1),
      },
    },
  })
);

export default function Note({
  text,
  createdZ,
  onDelete,
}: {
  text: string;
  createdZ: string;
  onDelete?: () => void;
}) {
  const styles = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const created = DateTime.fromISO(createdZ);
  const formattedCreatedDate = `${created.toLocaleString(
    DateTime.DATE_SHORT
  )} at ${created.toLocaleString(DateTime.TIME_SIMPLE)}`;

  const handleDeleteClick = () => setShowConfirmDelete(true);

  return (
    <Card className={styles.root} elevation={0}>
      <Typography className={styles.noteText}>{text}</Typography>
      <Box className={styles.noteFooter}>
        <Typography className={styles.datetext}>{formattedCreatedDate}</Typography>
        {onDelete && (
          <>
            <IconButton className={styles.button} onClick={handleDeleteClick} size="large">
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
            <MessageModal
              open={showConfirmDelete}
              closeLabel="Cancel"
              onClose={() => setShowConfirmDelete(false)}
              confirmLabel="Delete"
              onConfirm={() => {
                onDelete?.();
                setShowConfirmDelete(false);
              }}
              header="Delete note"
              info="Are you sure you wish to delete this note?"
            />
          </>
        )}
      </Box>
    </Card>
  );
}
