import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { VisitRequestFieldsFragment } from 'api';
import { Card, TitleWithSubtitle } from 'components';
import { displayValue } from 'system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
      marginTop: theme.spacing(0.5),
    },
  })
);

export default function FieldsCard({ fields }: { fields: VisitRequestFieldsFragment['fields'] }) {
  const styles = useStyles();
  return (
    <Card>
      <Typography className={styles.title}>Tenant Appointment Information</Typography>
      {(fields || []).map((field, i) => (
        <TitleWithSubtitle key={i} title={field.label ?? ''} subtitle={displayValue(field)} />
      ))}
    </Card>
  );
}
