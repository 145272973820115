import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useEmptyDataRedirect = <T>({
  data,
  loading,
  redirectUrl,
}: {
  data: T | undefined;
  loading: boolean;
  redirectUrl: string;
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!data && !loading) {
      history.push(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);
};
