import * as Icons from '@mui/icons-material';
import { SvgIconProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Categories } from 'system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primaryBackground.main,
      color: theme.palette.primary.main,
      padding: theme.spacing(0.8, 1),
      height: 40,
      width: 40,
      borderRadius: 40,
    },
  })
);

const iconComponent = (category: Categories) =>
  ({
    APPLIANCE: Icons.LocalLaundryService,
    CLEANING: Icons.Delete,
    DOOR: Icons.MeetingRoom,
    ELECTRICAL: Icons.OfflineBolt,
    EXTERIOR: Icons.NaturePeople,
    GENERAL: Icons.Build,
    HVAC: Icons.Waves,
    KEYS: Icons.VpnKey,
    PLUMBING: Icons.Bathtub,
    SAFETY: Icons.VerifiedUser,
    SECURITY: Icons.Security,
    WINDOW: Icons.Storefront,
  }[category]);

const validCategory = (category: string): category is Categories =>
  Object.keys(Categories).includes(category);

export default function CategoryIcon({ category, ...props }: { category: string } & SvgIconProps) {
  const styles = useStyles();
  const Icon = validCategory(category) ? iconComponent(category) : Icons.Build;

  return <Icon {...props} className={styles.root} />;
}
