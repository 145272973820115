import { useVisitRequiredMutation } from 'api';
import { useAllErrors } from 'hooks';
import { useState } from 'react';
import { Categories } from 'system';

export const useVisitRequired = ({
  requestId,
  category,
}: {
  requestId: string;
  category?: string;
}) => {
  const [localError, setLocalError] = useState('');
  const [visitRequiredMutation, { error: followUpError }] = useVisitRequiredMutation();
  const [note, setNote] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(category ?? Categories.GENERAL);

  useAllErrors(localError, followUpError);

  const requireVisit = async ({
    accountId,
    note,
    category,
  }: {
    accountId: string;
    note: string;
    category: string;
  }) => {
    try {
      const input = { requestId, accountId, note, category };
      await visitRequiredMutation({ variables: { input } });
    } catch (e) {
      console.warn('Failed to create visit', e);
      setLocalError('Failed to submit follow-up visit. Please try again later.');
    }
  };

  return { requireVisit, note, setNote, selectedCategory, setSelectedCategory };
};
