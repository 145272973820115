import { AppBar, FormControlLabel, Switch, Theme, Toolbar } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from 'context';
import { Logo } from 'components';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      marginTop: theme.spacing(1.3),
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(1.3),
      marginRight: theme.spacing(4),
    },
    grow: {
      flexGrow: 1,
    },
  });
});

export default function SiteNav() {
  const { darkMode, toggleDarkMode } = useTheme();
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position="fixed" elevation={0}>
      <Toolbar className={classes.toolbar} variant="dense" disableGutters>
        <Logo color="primary" />
        <div className={classes.grow} />
        <FormControlLabel
          labelPlacement="start"
          control={<Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />}
          label="Dark mode"
        />
      </Toolbar>
    </AppBar>
  );
}
