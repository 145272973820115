import { Box, Button, Input, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useState } from 'react';
import { CreateNoteFormFields, Domain } from 'system';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkButtonContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    linkButton: {
      backgroundColor: theme.palette.background.default,
      display: 'inline-block',
      marginTop: theme.spacing(1),
      textTransform: 'none',
      '&--save': {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
      },
      '&--cancel': {
        color: theme.palette.error.main,
      },
    },
    buttonContainer: {
      marginTop: theme.spacing(5),
    },
    button: {
      width: '100%',
    },
    errorText: {
      fontSize: '13px',
      fontWeight: 200,
    },
    input: {
      backgroundColor: theme.palette.background.default,
      fontSize: '15px',
      padding: theme.spacing(2, 1),
    },
    root: {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      marginTop: theme.spacing(2),
      width: '100%',
    },
  })
);

const createRequestNoteValidationSchema = Yup.object()
  .required()
  .shape({
    target: Yup.mixed<Domain>()
      .oneOf(['tenant', 'manager'])
      .required('Please choose a recipient for this note'),
    text: Yup.string()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .when('target', (_: unknown, schema) => schema)
      .required('Please enter some notes')
      .trim(),
  });

type FormError<T extends Record<string, unknown>> = {
  [key in keyof T]?: string;
};

type Props = {
  target?: Domain;
  onCancel?: () => void;
  onSubmit: (noteForm: Required<CreateNoteFormFields>) => void;
  showCancel?: boolean;
  buttonType?: 'link' | 'button';
  submitButtonLabel?: string;
};

export default function NoteForm({
  onSubmit,
  onCancel,
  target,
  showCancel = true,
  buttonType = 'link',
  submitButtonLabel = 'Save',
}: Props) {
  const styles = useStyles();

  const [noteForm, setNoteForm] = useState<CreateNoteFormFields>({ target });
  const [formErrors, setFormErrors] = useState<FormError<CreateNoteFormFields>>({});

  const submitForm = () => {
    try {
      onSubmit(createRequestNoteValidationSchema.validateSync(noteForm));
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        setFormErrors({ [e.path ?? '']: e.message });
      }
    }
  };

  const hasError = Boolean(formErrors.text);

  return (
    <Box className={styles.root}>
      <Input
        className={styles.input}
        error={hasError}
        fullWidth
        maxRows={3}
        multiline
        onChange={({ target: { value } }) => setNoteForm({ ...noteForm, text: value })}
        value={noteForm.text}
      />
      {hasError && (
        <Typography className={styles.errorText} color="error">
          {formErrors.text}
        </Typography>
      )}

      <Box
        className={buttonType === 'button' ? styles.buttonContainer : styles.linkButtonContainer}
      >
        {showCancel && (
          <Button
            {...(buttonType === 'button'
              ? {
                  color: 'secondary',
                  className: styles.button,
                  variant: 'contained',
                  size: 'large',
                }
              : {
                  className: clsx(styles.linkButton, `${styles.linkButton}--cancel`),
                })}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={submitForm}
          {...(buttonType === 'button'
            ? {
                color: 'secondary',
                className: styles.button,
                variant: 'contained',
                size: 'large',
              }
            : {
                className: clsx(styles.linkButton, `${styles.linkButton}--save`),
              })}
        >
          {submitButtonLabel}
        </Button>
      </Box>
    </Box>
  );
}
