import { Storage } from '@aws-amplify/storage';
import { API, useAddImageWithLocationMutation } from 'api';
import { useAllErrors } from 'hooks';
import { useEffect, useState } from 'react';

const buildFileKey = ({ visitId, filename }: { visitId?: string; filename: string }) =>
  `${visitId ? `visits/${visitId}/` : ''}operator/${Date.now()}-${filename}`;

type UsePhotoProps =
  | {
      requestId: string;
    }
  | {
      requestId: string;
      visitId: string;
    };

export const usePhoto = ({ requestId, ...visitKey }: UsePhotoProps) => {
  const [localError, setLocalError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [location, setLocation] = useState('{}');
  const [uploadedImage, setUploadedImage] = useState<{ key: string; uri: string } | undefined>();
  const [addImageWithLocation, { error: addImageError }] = useAddImageWithLocationMutation();

  useAllErrors(addImageError, localError);

  useEffect(() => {
    if (navigator?.geolocation?.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition((position) => setLocation(JSON.stringify(position)));
    }
  }, []);

  const pickImage = async (file: File | undefined) => {
    if (file) {
      setUploading(true);
      await uploadImage(file);
    }
  };

  const uploadImage = async (file: File) => {
    const { name, type } = file;
    if (!type.includes('image')) return;
    const prefixedFilename = buildFileKey({ ...visitKey, filename: name });
    setUploadedImage({ key: prefixedFilename, uri: URL.createObjectURL(file) });
    const key = `requests/${requestId}/${prefixedFilename}`;
    const input = { ...visitKey, requestId, key: prefixedFilename, location };
    await Storage.put(key, file)
      .then(() =>
        addImageWithLocation({
          variables: { input },
          refetchQueries: ['visitId' in visitKey && visitKey.visitId ? API.Query.GetVisit : ''],
        })
      )
      .catch((e: unknown) => {
        console.error('Failed to upload image', e);
        setLocalError('Failed to upload image. Please try again later.');
      });
    setUploading(false);
  };

  const deleteImageFromS3 = async (key: string) => {
    if (key) {
      try {
        setDeleting(true);
        if (key === uploadedImage?.key) {
          setUploadedImage(undefined);
        }
        await Storage.remove(`requests/${requestId}/${key}`);
      } catch (e) {
        console.error('Failed to delete image', e);
        setLocalError('Failed to delete image. Please try again later.');
      }
    }
    setDeleting(false);
  };

  return { pickImage, deleting, uploading, uploadedImage, deleteImageFromS3 };
};
