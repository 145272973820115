import { AuthActions } from './actions';
import { AuthAction, AuthPayload, AuthState } from './types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const localCurrentUserCredentials: AuthPayload = localStorage.getItem('currentUserCredentials')
  ? JSON.parse(localStorage.getItem('currentUserCredentials') ?? '{}')
  : null;

export const initialState = {
  credentials: localCurrentUserCredentials,
  visitId: undefined,
};

export const authReducer: React.Reducer<AuthState, AuthAction> = (
  defaultState: AuthState,
  action: AuthAction
) => {
  switch (action.type) {
    case AuthActions.SIGN_IN:
    case AuthActions.REFRESH_TOKEN:
      return {
        ...defaultState,
        credentials: action.payload,
      };
    case AuthActions.SIGN_OUT:
      return {
        ...defaultState,
        credentials: undefined,
      };
    case AuthActions.SET_VISIT:
      return {
        ...defaultState,
        visitId: action.payload,
      };

    default:
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`);
  }
};
