import { useSnackbar, VariantType } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const sendSuccessNotification = (message: string) => sendNotification(message, 'success');

  const sendNotification = (message: string, type?: VariantType) => {
    enqueueSnackbar((message as unknown as Error).message || message, {
      variant: type ?? 'default',
      autoHideDuration: 5000,
    });
  };

  return {
    sendNotification,
    sendSuccessNotification,
  };
};
