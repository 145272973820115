import { useAuth } from 'context';
import * as Pages from 'pages';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as Frames from './frames';
import { DefaultFrame } from './frames';

export type AppRouteProps = {
  component: React.ComponentType<typeof Pages>;
  frame?: keyof typeof Frames;
  path: string;
  exact?: boolean;
  isPrivate?: boolean;
};

export default function AppRoute({
  component,
  frame,
  path,
  exact,
  isPrivate,
  ...rest
}: AppRouteProps) {
  const { credentials } = useAuth();
  const Frame = frame ? Frames[frame] : DefaultFrame;
  const Component = component;
  const isLoggedIn = Boolean(credentials?.userToken);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (isPrivate && !isLoggedIn) {
          return <Redirect push={true} to={{ pathname: '/', state: { from: props.location } }} />;
        }

        return (
          <Frame>
            <Component />
          </Frame>
        );
      }}
      {...rest}
    />
  );
}
