import { Email, FileCopy, Phone } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';
import { TitleWithSubtitle } from 'components';
import { useNotification } from 'hooks';
import { compact } from 'lodash';
import { ensureArray, formatPhone } from 'system';

enum Notifications {
  emailCopied = 'Email copied to clipboard',
  phoneCopied = 'Phone copied to clipboard',
}

export default function TenantInfo({
  tenant,
}: {
  tenant: {
    name?: string;
    phone?: string;
    email?: string;
    emails?: string[];
  };
}) {
  const { sendNotification } = useNotification();

  const copyText = async (text: string) => navigator.clipboard.writeText(text);
  const copyEmail = (email: string) => {
    void copyText(email);
    sendNotification(Notifications.emailCopied, 'success');
  };
  const copyPhone = () => {
    void copyText(tenant?.phone ?? '');
    sendNotification(Notifications.phoneCopied, 'success');
  };

  return tenant?.name ? (
    <>
      <TitleWithSubtitle title={tenant.name} subtitle="" />
      {tenant.phone && (
        <Typography color="textSecondary" fontSize="13px" marginTop={0.5}>
          {formatPhone(tenant.phone)}
          &nbsp;
          <FileCopy sx={{ mr: 1 }} onClick={copyPhone} fontSize="inherit" color="primary" />
          <Link href={`tel:${tenant.phone}`}>
            <Phone sx={{ mr: 1 }} fontSize="inherit" color="primary" />
          </Link>
        </Typography>
      )}
      {compact([tenant.email, ...ensureArray(tenant.emails)]).map((email) => (
        <Typography color="textSecondary" fontSize="13px" marginTop={0.5} key={email}>
          {email}
          <FileCopy
            sx={{ mx: 1 }}
            onClick={() => copyEmail(email)}
            fontSize="inherit"
            color="primary"
          />
          <Link href={`mailto:${email}`}>
            <Email sx={{ mr: 1 }} fontSize="inherit" color="primary" />
          </Link>
        </Typography>
      ))}
    </>
  ) : (
    <></>
  );
}
