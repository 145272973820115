import { CircularProgress, Grid } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
    },
  })
);

export default function FullScreenSpinner() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <CircularProgress size={240} />
    </Grid>
  );
}
