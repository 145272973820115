import { FullScreenSpinner } from 'components';
import { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { routes } from './routes';

export default function Navigation() {
  return (
    <Router>
      <Suspense fallback={<FullScreenSpinner />}>
        <Switch>
          {routes.map((route) => (
            <AppRoute key={route.path} {...route} />
          ))}
        </Switch>
      </Suspense>
    </Router>
  );
}
