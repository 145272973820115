import { ApolloLink } from '@apollo/client';

type Headers = {
  authorization?: string;
};

const authLink = (userToken?: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Headers }) => ({
      headers: {
        ...headers,
        authorization: userToken,
      },
    }));

    return forward(operation);
  });

export default authLink;
