import { ImageList, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Image as ImageType } from 'api';
import { Card, ImagePicker, ThumbnailImage } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
  })
);

type Props = {
  images?: ImageType[];
  visitId: string;
  label: string;
  pickImage?: (file: File | undefined) => void;
  uploadedImage?: {
    key: string;
    uri: string;
  };
  onDelete?: (key: string) => void;
};

export default function PhotoList({
  images,
  visitId,
  label,
  pickImage,
  uploadedImage,
  onDelete,
}: Props) {
  const styles = useStyles();

  return (
    <Card>
      <Typography className={styles.title}>{label}</Typography>
      <ImageList cols={4} rowHeight="auto">
        {(images ?? [])
          .filter((image) => image.key !== uploadedImage?.key)
          .map((image) => (
            <ThumbnailImage
              key={image.key}
              imageKey={image.key}
              baseUrl={`requests/${visitId}`}
              onDelete={onDelete}
            />
          ))}
        {uploadedImage && (
          <ThumbnailImage
            uri={uploadedImage.uri}
            imageKey={uploadedImage.key}
            key={uploadedImage.key}
            onDelete={onDelete}
          />
        )}
      </ImageList>
      {pickImage && <ImagePicker inputId="photo-list-picker" pickImage={pickImage} />}
    </Card>
  );
}
