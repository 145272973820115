import { Alert, Box, Button, Card, Dialog, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      '&__button': {
        marginBottom: theme.spacing(2),

        '&:only-child, &:last-child': {
          margin: 0,
        },
      },
    },
    info: {
      fontSize: '16px',
      margin: theme.spacing(2, 0),
    },
    root: {
      margin: theme.spacing(1.5),
    },
    card: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  })
);

export default function MessageModal({
  open,
  onClose,
  info,
  header,
  closeLabel = 'Close',
  confirmLabel,
  onConfirm,
  warning,
}: {
  open: boolean;
  onClose: VoidFunction;
  info: string;
  header?: string;
  closeLabel?: string;
  confirmLabel?: string;
  onConfirm?: VoidFunction;
  warning?: ReactNode;
}) {
  const styles = useStyles();

  return (
    <Dialog className={styles.root} {...{ open, onClose }}>
      <Card className={styles.card}>
        {header && <Typography variant="h6">{header}</Typography>}
        <Typography className={styles.info}>{info}</Typography>
        {warning && (
          <Alert sx={{ mb: 2 }} severity="warning">
            {warning}
          </Alert>
        )}
        <Box className={styles.buttonContainer}>
          {confirmLabel && (
            <Button
              className={`${styles.buttonContainer}__button`}
              color="primary"
              variant="contained"
              fullWidth
              onClick={onConfirm}
            >
              {confirmLabel}
            </Button>
          )}
          <Button
            className={`${styles.buttonContainer}__button`}
            variant="outlined"
            fullWidth
            onClick={onClose}
          >
            {closeLabel}
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
}
