import { Grid, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: 0,
      },
    },
    title: {
      fontSize: '15px',
      fontWeight: 500,
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
      marginTop: theme.spacing(0.5),
    },
  })
);

type Props = {
  title: string;
  subtitle: string;
};

export default function TitleWithSubtitle({ title, subtitle }: Props) {
  const styles = useStyles();

  return (
    <Grid className={styles.root} item>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.subtitle}>{subtitle}</Typography>
    </Grid>
  );
}
