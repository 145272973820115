import { loginUser, useAuthDispatch } from 'context';
import { ParsedQuery } from 'query-string';

export type LoginFormFields = {
  email: string;
};

export const useMagicLink = () => {
  const authDispatch = useAuthDispatch();

  const signInDirectly = async ({ params }: { params: ParsedQuery<string> }) => {
    const { email: paramEmail, challenge: paramChallenge } = params;

    if (!paramEmail || !paramChallenge) {
      return;
    }

    const email = paramEmail as string;
    const challenge = paramChallenge as string;
    try {
      await loginUser(authDispatch, { email, challenge });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    signInDirectly,
  };
};
