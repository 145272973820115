import { useState } from 'react';

export const useModalControl = <T = unknown>(
  defaultFocus?: T
): [VoidFunction | ((newFocus?: T) => void), VoidFunction, boolean, T | undefined] => {
  const [state, setState] = useState<{ open: boolean; focus: T | undefined }>({
    open: false,
    focus: undefined,
  });
  const handleHide = () => setState((state) => ({ ...state, open: false }));
  const handleShow = (focus?: T): void => setState({ open: true, focus: focus ?? defaultFocus });

  return [handleShow, handleHide, state.open, state.focus];
};
