import { Card as MUICard, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bordered: {
      border: '0 solid',
      borderRadius: '4px',
      borderLeftWidth: '4px',
      borderLeftColor: theme.palette.primary.main,
    },
    card: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },

      '&:first-child': {
        marginTop: 0,
      },
    },
  })
);

export type CardProps = { children: ReactNode; type?: 'bordered'; className?: string };

export default function Card({ children, type, className = '' }: CardProps) {
  const styles = useStyles();
  return (
    <MUICard className={clsx(styles.card, type && styles[type], className)}>{children}</MUICard>
  );
}
