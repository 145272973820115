import { ChevronLeft as ChevronLeftIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, ImageListItem, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useGetS3Image } from 'hooks';
import { useState } from 'react';
import { prefixFilename, THUMBNAIL_SIZE_SQUARE } from 'system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: 25,

      '&--cancel': {
        left: 10,
      },
      '&--delete': {
        right: 10,
      },
    },
    modal: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
    image: {
      display: 'block',
      width: '100%',
    },
    thumbnail: {
      backgroundSize: 'cover',
      borderRadius: '11px',
      height: THUMBNAIL_SIZE_SQUARE,
      width: THUMBNAIL_SIZE_SQUARE,
    },
  })
);

type Props = {
  imageKey?: string;
  baseUrl?: string;
  uri?: string;
  onDelete?: (key: string) => void;
};

export default function ThumbnailImage({ baseUrl = '#', imageKey, uri, onDelete }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const thumbnailName = prefixFilename({ filename: imageKey, prefix: 'thumbnail' }) ?? '';
  const { image: thumbnailUri } = useGetS3Image(`${baseUrl}/${thumbnailName}`) ?? '';

  const optimizedName = prefixFilename({ filename: imageKey, prefix: 'optimized' }) ?? '';
  const { image: optimizedUri } = useGetS3Image(`${baseUrl}/${optimizedName}`) ?? '';

  const source = {
    thumbnail: {
      uri: uri ?? thumbnailUri,
    },
    optimized: {
      uri: uri ?? optimizedUri,
    },
  };

  const styles = useStyles();

  if (!source.thumbnail.uri) return <></>;

  return (
    <>
      <ImageListItem>
        <img
          alt="Operator uploaded"
          className={styles.thumbnail}
          onClick={() => setIsModalOpen(true)}
          src={source.thumbnail.uri}
        />
      </ImageListItem>
      <Modal className={styles.modal} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <>
          <IconButton
            className={clsx(styles.button, `${styles.button}--cancel`)}
            onClick={() => setIsModalOpen(false)}
            size="large"
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>

          {onDelete && (
            <IconButton
              className={clsx(styles.button, `${styles.button}--delete`)}
              onClick={() => onDelete(imageKey ?? '')}
              size="large"
            >
              <DeleteIcon color="error" fontSize="large" />
            </IconButton>
          )}
          <Box>
            <img className={styles.image} alt="Operator uploaded" src={source.optimized.uri} />
          </Box>
        </>
      </Modal>
    </>
  );
}
