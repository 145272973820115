import { Box, Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { RequestFieldsFragment } from 'api';
import { Info as InfoIcon } from '@mui/icons-material';
import { Domain, CreateNoteFormFields, capitalize, sortBy } from 'system';
import { MessageModal, Note, NoteForm, Card } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
    },
    buttonText: {
      color: theme.palette.primary.main,
      fontSize: '13px',
      fontWeight: 500,
    },
    emptyText: {
      fontSize: '15px',
      fontWeight: 500,
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    icon: {
      fontSize: '20px',
      marginLeft: theme.spacing(0.5),
    },
    title: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
  })
);

type NotesProps = {
  info?: string;
  domain: Domain;
  editable: boolean;
  onDelete?: (id: string) => void;
  notes: NonNullable<RequestFieldsFragment['notes']>;
  onSubmit?: (noteForm: Required<CreateNoteFormFields>) => void;
};

export default function NotesCard({
  info,
  notes,
  domain,
  editable,
  onSubmit,
  onDelete,
}: NotesProps) {
  const styles = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const sortedNotes = sortBy(notes, (note) => Date.parse(note.createdZ ?? ''), 'desc');

  const startEditing = () => {
    setEditMode(true);
  };

  const stopEditing = () => {
    setEditMode(false);
  };

  const handleSubmit = (note: Required<CreateNoteFormFields>) => {
    onSubmit?.(note);
    stopEditing();
  };

  return (
    <Card>
      <Box alignItems="center" display="flex" flexDirection="row">
        <Typography className={styles.title}>{capitalize(domain)} notes</Typography>
        {info && (
          <>
            <InfoIcon
              className={styles.icon}
              color="primary"
              onClick={() => setShowInfoAlert(true)}
            />
            <MessageModal
              open={showInfoAlert}
              onClose={() => setShowInfoAlert(false)}
              info={info}
            />
          </>
        )}
      </Box>
      {editMode && <NoteForm target={domain} onSubmit={handleSubmit} onCancel={stopEditing} />}
      {!editMode && sortedNotes.length === 0 && (
        <Box>
          <Typography className={styles.emptyText}>No notes entered</Typography>
        </Box>
      )}
      {sortedNotes.length > 0 && (
        <Box>
          {sortedNotes.map((note) => (
            <Note
              key={note.id}
              text={note.text ?? ''}
              createdZ={note.createdZ ?? ''}
              onDelete={() => onDelete?.(note.id ?? '')}
            />
          ))}
        </Box>
      )}
      {!editMode && editable && (
        <Button className={styles.button} onClick={startEditing}>
          <Typography className={styles.buttonText}>Add a note</Typography>
        </Button>
      )}
    </Card>
  );
}
