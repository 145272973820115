import { AppBar, Typography, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      textAlign: 'center',
    },
    header: {
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
      },
      fontSize: '17px',
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: '100%',
    },
  })
);

export default function Header({ title }: { title: string }) {
  const styles = useStyles();

  return (
    <AppBar position="fixed" elevation={0} className={styles.container}>
      <Typography className={styles.header}>{title}</Typography>
    </AppBar>
  );
}
