import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Card } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
    subtitle: {
      fontSize: '15px',
      fontWeight: 500,
    },
  })
);

export default function InstructionsCard({ instructions }: { instructions: string }) {
  const styles = useStyles();
  return (
    <Card type="bordered">
      <Typography className={styles.title}>Manager note:</Typography>
      <Typography className={styles.subtitle}>{instructions}</Typography>
    </Card>
  );
}
