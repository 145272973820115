import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { Dispatch } from 'react';
import authLink from './authLink';
import errorLink from './errorLink';
import { AuthAction } from 'context';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_APPSYNC_GRAPHQL });

const cache = new InMemoryCache();

const client = (dispatch: Dispatch<AuthAction>, userToken?: string) =>
  new ApolloClient({
    link: from([errorLink(dispatch), authLink(userToken), httpLink]),
    cache,
    credentials: 'include',
  });

export default client;
