import { Storage } from '@aws-amplify/storage';
import { useAuth } from 'context';
import { UseFormReturn } from 'react-hook-form';
import { DOCUMENT_BUCKET } from 'system';
import { UpdateVisitBillInput, useUpdateVisitBillMutation } from '../api';
import { useNotification } from './useNotification';

export type BillCardFormValues = {
  due?: string;
  date: string;
  totalAmount: number;
  gstAmount?: number;
  upload?: File;
  ref?: string;
  key?: string;
};

const getKey = ({
  accountId,
  operatorId,
  requestId,
  visitId,
  filename,
}: {
  accountId: string;
  operatorId: string;
  requestId: string;
  visitId: string;
  filename: string;
}) =>
  `accounts/${accountId}/requests/${requestId}/visits/${visitId}/operator/${operatorId}/${filename}`;

enum Notifications {
  REMOVE_ERROR = 'Error removing bill document',
  UPLOAD_ERROR = 'Error saving bill document',
  ERROR = 'Error saving bill details',
  SUCCESS = 'Bill details saved successfully',
  REMOVE_SUCCESS = 'Bill document removed',
}

export const useBill = ({
  accountId,
  requestId,
  visitId,
}: {
  accountId: string;
  requestId: string;
  visitId: string;
}) => {
  const [updateVisitBill] = useUpdateVisitBillMutation();
  const { sendNotification } = useNotification();
  const { credentials } = useAuth();
  const operatorId = credentials?.attributes?.id;

  const removeKey =
    ({ setValue, getValues }: UseFormReturn<BillCardFormValues>) =>
    async () => {
      const key = getValues('key');
      if (key) {
        try {
          if (!operatorId) {
            throw new Error('Credentials not found');
          }
          const fullKey = getKey({ accountId, operatorId, requestId, visitId, filename: key });
          await Storage.remove(fullKey, { bucket: DOCUMENT_BUCKET });
          setValue('key', undefined, { shouldDirty: false });
          sendNotification(Notifications.REMOVE_SUCCESS, 'success');
        } catch (err: unknown) {
          console.error(err);
          sendNotification(Notifications.REMOVE_ERROR, 'error');
        }
      }
    };

  const saveBill =
    ({ setValue, reset }: UseFormReturn<BillCardFormValues>) =>
    async (values: BillCardFormValues) => {
      if (values.upload) {
        try {
          if (!operatorId) {
            throw new Error('Credentials not found');
          }
          const fullKey = getKey({
            accountId,
            operatorId,
            requestId,
            visitId,
            filename: values.upload.name,
          });
          await Storage.put(fullKey, values.upload, { bucket: DOCUMENT_BUCKET });
          setValue('key', values.upload.name, { shouldDirty: false });
        } catch (err: unknown) {
          console.error(err);
          sendNotification(Notifications.UPLOAD_ERROR, 'error');
          return false;
        }
      }

      const input: UpdateVisitBillInput = {
        visitId,
        due: values.due,
        date: values.date,
        totalAmount: values.totalAmount,
        gstAmount: values.gstAmount,
        ref: values.ref,
        key: values.key ?? values.upload?.name,
      };

      const { data } = await updateVisitBill({ variables: { input } });
      if (data?.updateVisitBill?.success) {
        reset(values);
        sendNotification(Notifications.SUCCESS, 'success');
      } else {
        console.error(data?.updateVisitBill?.error);
        sendNotification(data?.updateVisitBill?.error ?? Notifications.ERROR, 'error');
      }

      return Boolean(data?.updateVisitBill?.success);
    };

  return { saveBill, removeKey };
};
