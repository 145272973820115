import { Storage } from '@aws-amplify/storage';
import gPhoneNumber from 'google-libphonenumber';
import { isEmpty, isNil, isObject, mapValues, omitBy, reject } from 'lodash';
import { DateTime } from 'luxon';
import { string } from 'yup';
import { emptyArray } from './constants';
import { Provinces } from './types';
const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

export const noOp = () => {
  /* no-op */
};

export const capitalize = (name?: string) =>
  name && name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();

export enum Categories {
  APPLIANCE = 'APPLIANCE',
  CLEANING = 'CLEANING',
  DOOR = 'DOOR',
  ELECTRICAL = 'ELECTRICAL',
  EXTERIOR = 'EXTERIOR',
  GENERAL = 'GENERAL',
  HVAC = 'HVAC',
  KEYS = 'KEYS',
  PLUMBING = 'PLUMBING',
  SAFETY = 'SAFETY',
  SECURITY = 'SECURITY',
  WINDOW = 'WINDOW',
}

export const formatAddress = (
  {
    street,
    city,
    province,
    postal,
  }: {
    street?: string;
    city?: string;
    province?: string;
    postal?: string;
  },
  unit?: string
) => {
  return [`Unit: ${unit ?? ''}`, street, city, province, postal].filter(Boolean).join(', ');
};

export const formatPhone = (phone: string) => {
  return phone
    ? phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phone, 'CA'),
        gPhoneNumber.PhoneNumberFormat.NATIONAL
      )
    : '';
};

export const displayValue = ({ value }: { value?: string }) =>
  !value
    ? '-'
    : value.toLowerCase() === 'true'
      ? 'Yes'
      : value.toLowerCase() === 'false'
        ? 'No'
        : value;

export const sortBy = <T>(
  collection: T[] | undefined,
  sortFn: (item: T) => number,
  direction: 'asc' | 'desc' = 'asc'
) => {
  return (collection ?? []).slice().sort((a, b) => {
    const sortA = sortFn(a);
    const sortB = sortFn(b);

    return direction === 'asc' ? sortA - sortB : sortB - sortA;
  });
};

export type ImageType = 'thumbnail' | 'optimized';

export const prefixFilename = ({ filename, prefix }: { filename?: string; prefix: ImageType }) =>
  filename?.replace(/\/([^/]+)([/]?)$/, `/${prefix}-$1$2`);

export const THUMBNAIL_SIZE_SQUARE = 69;

export const fetchS3Image = async (key: string) => await Storage.get(key);

export const parseDates = <TDates extends unknown[]>(...dates: TDates) =>
  dates.map((date) =>
    DateTime.isDateTime(date)
      ? date
      : date instanceof Date
        ? DateTime.fromJSDate(date)
        : typeof date === 'string'
          ? DateTime.fromISO(date)
          : DateTime.invalid('invalid date')
  );

export const yupDateAsString = (message?: string) =>
  string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v instanceof DateTime
        ? v.toString().substring(0, 10)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(0, 10)
          : v instanceof Date
            ? DateTime.fromJSDate(v).toString().substring(0, 10)
            : '';
    })
    .test('isValidDate', message ?? 'Invalid Date', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.schema.spec.presence !== 'required';
    });

export const parseCurrencyFloat = (currencyStr?: string): number =>
  currencyStr ? safeRound(parseFloat(String(currencyStr).replace(/[,$]/g, ''))) : 0;

export const safeRound = (arg: number, digits = 2) =>
  Math.round(arg * Math.pow(10, digits)) / Math.pow(10, digits);

export const safeSum = (...args: (number | number[])[]) =>
  args.flat().reduce((a, c) => safeRound(a + c), 0);

export const spreadIf = <P = unknown, T = unknown>(predicate: P, obj?: T) =>
  predicate ? (obj ? obj : predicate) : {};

export const DEFAULT_GST = 0.05;

export const validProvince = (province: unknown): province is keyof typeof Provinces =>
  typeof province === 'string' && Object.keys(Provinces).includes(province);

export const gstByProvince: Record<keyof typeof Provinces, number> = {
  [Provinces.AB]: DEFAULT_GST,
  [Provinces.BC]: DEFAULT_GST,
  [Provinces.MB]: DEFAULT_GST,
  [Provinces.NT]: DEFAULT_GST,
  [Provinces.QC]: DEFAULT_GST,
  [Provinces.SK]: DEFAULT_GST,
  [Provinces.YT]: DEFAULT_GST,
  [Provinces.NU]: DEFAULT_GST,
  [Provinces.ON]: 0.13,
  [Provinces.NB]: 0.15,
  [Provinces.NL]: 0.15,
  [Provinces.NS]: 0.15,
  [Provinces.PE]: 0.15,
};

export const isNilOrEmptyObject = (
  item: unknown
): item is null | undefined | Record<string, never> =>
  isNil(item) || (typeof item === 'object' && !Array.isArray(item) && isEmpty(item));

export const stripNonData = <T = unknown>(
  item: T
): T extends null | undefined | Record<string, never> ? undefined : T => {
  return (
    isNilOrEmptyObject(item)
      ? undefined
      : Array.isArray(item)
        ? reject(item.map(stripNonData), isNilOrEmptyObject)
        : isObject(item) && !DateTime.isDateTime(item)
          ? omitBy(
              mapValues(item, stripNonData),
              (value: unknown, key: string) => key === '__typename' || isNilOrEmptyObject(value)
            )
          : item
  ) as T extends null | undefined | Record<string, never> ? undefined : T;
};

export const ensureArray = <T = unknown[] | null | undefined>(arr?: T) =>
  arr ?? (emptyArray as unknown as NonNullable<T>);
