import { ApolloProvider } from '@apollo/client';
import { createContext, Dispatch, useContext, useReducer } from 'react';
import { Children } from 'system';
import client from './apolloClient';
import { authReducer, initialState } from './reducer';
import { AuthAction } from './types';
import { AuthContext } from './useAuth';

const AuthDispatchContext = createContext<Dispatch<AuthAction> | undefined>(undefined);

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AppProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: Children) => {
  const [user, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        <ApolloProvider client={client(dispatch, user?.credentials?.userToken)}>
          {children}
        </ApolloProvider>
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
