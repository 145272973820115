import { CssBaseline, StyledEngineProvider, Theme } from '@mui/material';
import { AuthProvider, ThemeProvider } from 'context';
import { Navigation } from 'navigation';
import { SnackbarProvider } from 'notistack';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline enableColorScheme />
        <SnackbarProvider>
          <AuthProvider>
            <Navigation />
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
