import { SvgIcon, SvgIconProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      height: '.4em',
      width: '1em',
      fontSize: '8rem',
    },
    shortLogo: {
      height: '.4em',
      width: '.4em',
      fontSize: '4.6rem',
    },
  })
);

export default function Logo({ short, className, ...props }: SvgIconProps & { short?: boolean }) {
  const classes = useStyles();
  return short ? (
    <SvgIcon viewBox="0 0 13 17" {...props} className={clsx(classes.shortLogo, className)}>
      <path d="M6.36228 0C4.97755 0 3.66766 0.453333 2.61976 1.24667V0.415556C2.61976 0.188889 2.43263 0 2.20808 0H0.0374252C0 0 0 0 0 0.0377778V17C0 17 0 17 0.0374252 17H2.61976C2.61976 17 2.61976 17 2.61976 16.9622V11.5978C3.66766 12.3911 4.97755 12.8444 6.36228 12.8444C9.88024 12.8444 12.7246 9.97333 12.7246 6.42222C12.7246 2.87111 9.88024 0 6.36228 0ZM6.36228 10.2C4.30389 10.2 2.61976 8.5 2.61976 6.42222C2.61976 4.34444 4.30389 2.64444 6.36228 2.64444C8.42066 2.64444 10.1048 4.34444 10.1048 6.42222C10.1048 8.5 8.42066 10.2 6.36228 10.2Z" />
    </SvgIcon>
  ) : (
    <SvgIcon viewBox="0 5 75 1" {...props} className={clsx(classes.logo, className)}>
      <path d="M16.766 6.422c0-2.078 1.685-3.778 3.743-3.778.898 0 1.721.34 2.395.87.3-.832.749-1.625 1.31-2.305A6.306 6.306 0 0020.509 0a6.197 6.197 0 00-3.743 1.247V.416A.417.417 0 0016.355 0h-2.171s-.037 0-.037.038v13.6h2.582v-2.002h.037V6.422zM6.362 0A6.197 6.197 0 002.62 1.247V.416A.417.417 0 002.208 0H.038C0 0 0 0 0 .038V17h2.62v-5.402a6.198 6.198 0 003.742 1.246c3.518 0 6.363-2.87 6.363-6.422C12.725 2.872 9.88 0 6.362 0zm0 10.2c-2.058 0-3.742-1.7-3.742-3.778 0-2.078 1.684-3.778 3.742-3.778 2.059 0 3.743 1.7 3.743 3.778 0 2.078-1.684 3.778-3.743 3.778zM55.127 6.422c0-2.078 1.684-3.778 3.743-3.778.786 0 1.497.227 2.096.643a8.626 8.626 0 011.347-2.267A6.138 6.138 0 0058.907 0a6.197 6.197 0 00-3.742 1.247V.416A.417.417 0 0054.753 0h-2.17s-.038 0-.038.038v13.6h2.582v-2.002h.038l-.038-5.214zM75 0h-2.246a.417.417 0 00-.411.416v.83A6.197 6.197 0 0068.6 0c-3.518 0-6.362 2.871-6.362 6.422 0 3.551 2.844 6.422 6.362 6.422a6.197 6.197 0 003.743-1.246v1.586c0 .227.187.416.411.416h2.209V0H75zm-6.362 10.2c-2.059 0-3.743-1.7-3.743-3.778 0-2.078 1.684-3.778 3.743-3.778 2.058 0 3.742 1.7 3.742 3.778 0 2.078-1.684 3.778-3.742 3.778zM30.614 0c-3.518 0-6.363 2.871-6.363 6.422 0 3.551 2.845 6.422 6.363 6.422 3.518 0 6.362-2.87 6.362-6.422 0-3.55-2.844-6.422-6.362-6.422zm0 10.2c-2.059 0-3.743-1.7-3.743-3.778 0-2.078 1.684-3.778 3.743-3.778 2.058 0 3.742 1.7 3.742 3.778 0 2.078-1.684 3.778-3.742 3.778zM44.76 0a6.197 6.197 0 00-3.742 1.247V.416A.417.417 0 0040.606 0h-2.17s-.038 0-.038.038V17s0 .038.038.038h2.582s.037 0 .037-.038v-5.402a6.198 6.198 0 003.743 1.246c3.518 0 6.362-2.87 6.362-6.422C51.123 2.872 48.28 0 44.76 0zm0 10.2c-2.058 0-3.742-1.7-3.742-3.778 0-2.078 1.684-3.778 3.742-3.778 2.059 0 3.743 1.7 3.743 3.778 0 2.078-1.684 3.778-3.742 3.778z" />
    </SvgIcon>
  );
}
